"use client";

import React from "react";
import { Icon } from "@iconify/react";
import Link from "next/link";

export const Footer = () => {
  const servicesLinks = [
    { name: "Coiffure de soirée", href: "/services/soiree" },
    { name: "Coiffure de mariage", href: "/services/mariage" },
    { name: "Soins du visage", href: "/services/soins" },
  ];

  const collectionsLinks = [
    { name: "Casa del Grey", href: "/collections/casa-del-grey" },
    { name: "Splash", href: "/collections/splash" },
    { name: "Dark Grey", href: "/collections/dark-grey" },
  ];

  const salonLinks = [
    { name: "Concept", href: "/salon/concept" },
    { name: "Les salons", href: "/salon/locations" },
    { name: "Blog", href: "/blog" },
  ];

  const socialLinks = [
    {
      name: "Instagram",
      icon: "formkit:instagram",
      href: "https://www.instagram.com/salonsalexandragrey/",
    },
    {
      name: "Facebook",
      icon: "formkit:facebook",
      href: "https://www.facebook.com/SalonsAlexandraGrey",
    },
  ];

  return (
    <footer className="bg-primary px-4 py-20 text-white ph:px-24 md:px-48 lg:px-72 xl:px-96">
      <div className="flex flex-col justify-between gap-16 lg:flex-row">
        {/* Brand Section */}
        <div className="lg:w-1/4">
          <div className="space-y-8">
            <h2 className="font-cormorant text-3xl">AG</h2>
            <div className="flex gap-6">
              {socialLinks.map((social) => (
                <Link
                  key={social.name}
                  href={social.href}
                  className="rounded-full bg-white p-2.5 transition-colors hover:bg-gray-200"
                >
                  <Icon
                    icon={social.icon}
                    className="h-6 w-6 text-primary"
                    aria-label={social.name}
                  />
                </Link>
              ))}
            </div>
          </div>
        </div>

        {/* Navigation Section */}
        <div className="lg:w-2/3">
          <div className="grid grid-cols-1 gap-12 md:grid-cols-3 md:gap-8">
            {/* Services Column */}
            <div className="space-y-6">
              <h3 className="text-lg">PRESTATIONS</h3>
              <ul className="space-y-4">
                {servicesLinks.map((link) => (
                  <li key={link.name}>
                    <Link
                      href={link.href}
                      className="text-[#C5C5C3] transition-colors hover:text-gray-300"
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            {/* Collections Column */}
            <div className="space-y-6">
              <h3 className="text-lg">COLLECTIONS</h3>
              <ul className="space-y-4">
                {collectionsLinks.map((link) => (
                  <li key={link.name}>
                    <Link
                      href={link.href}
                      className="text-[#C5C5C3] transition-colors hover:text-gray-300"
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            {/* Salon Column */}
            <div className="space-y-6">
              <h3 className="text-lg">LE SALON</h3>
              <ul className="space-y-4">
                {salonLinks.map((link) => (
                  <li key={link.name}>
                    <Link
                      href={link.href}
                      className="text-[#C5C5C3] transition-colors hover:text-gray-300"
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="mt-24 flex flex-col items-center justify-between gap-6 border-t border-zinc-800 pt-8 md:flex-row">
        <div>
          <p className="text-sm text-gray-400 md:order-1">
            © 2024 <span className="text-white">Alexandra Grey.</span> Tous
            droits réservés.
          </p>

          <p className="text-center text-sm text-gray-400 md:text-left">
            Site développé par{" "}
            <Link
              href="https://www.next-generation.dev/fr"
              target="_blank"
              className="transition-colors hover:text-gray-300"
            >
              <span className="text-white">Next Generation.</span>
            </Link>
          </p>
        </div>

        <div className="flex gap-8 text-sm text-white md:order-2">
          <Link href="/login" className="transition-colors hover:text-gray-300">
            Gestion du site
          </Link>
          <Link
            href="/mentions-legales"
            className="transition-colors hover:text-gray-300"
          >
            Mentions légales
          </Link>
          <Link
            href="/cookies"
            className="transition-colors hover:text-gray-300"
          >
            Gestion des cookies
          </Link>
        </div>
      </div>
    </footer>
  );
};
